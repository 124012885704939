import React from "react"

const defaultProps = {
  hidden: true,
}

type Props = { className?: string; hidden?: boolean } & typeof defaultProps

const Logo = ({ className, hidden }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 493.19 493.19"
    aria-hidden={hidden ? `true` : `false`}
    focusable={hidden ? `false` : `true`}
    aria-label={hidden ? `` : `LekoArts Logo`}
    fill="currentColor"
  >
    <g className="lekoarts-logo">
      <g className="lekoarts-logo__wrapper">
        <g className="lekoarts-logo__inner">
          <path d="M246.59,79.79A152.45,152.45,0,0,0,99.18,193.45a140.7,140.7,0,0,1,24,78.81,141.46,141.46,0,0,1-4.62,35.91,141.92,141.92,0,0,1,66.11,68.31A151.4,151.4,0,0,0,242,357.63a153.12,153.12,0,0,0-1.2-43.33,162.22,162.22,0,0,0-31.28-26.82l-1.22-.8a155.87,155.87,0,0,0,2.7-19.47c.29-3.91.46-7.85.46-11.84a155.72,155.72,0,0,0-7.73-48.59,159.83,159.83,0,0,1-68.46-27.57,160.52,160.52,0,0,1,51.26-8.35h.05A162.32,162.32,0,0,1,205,171.92a154.71,154.71,0,0,1,11.1,36.25A157,157,0,0,1,215,268.05c9.93,2,21.78,3.52,31.59,3.52h0c9.82,0,21.66-1.57,31.59-3.52a157,157,0,0,1-1.07-59.88,154.71,154.71,0,0,1,11.1-36.25,162.32,162.32,0,0,1,18.42-1.05h.05a160.52,160.52,0,0,1,51.26,8.35,159.83,159.83,0,0,1-68.46,27.57,155.72,155.72,0,0,0-7.73,48.59c0,4,.17,7.93.46,11.84a155.87,155.87,0,0,0,2.7,19.47l-1.22.8A162.22,162.22,0,0,0,252.4,314.3a153.12,153.12,0,0,0-1.2,43.33,151.4,151.4,0,0,0,57.31,18.86,141.92,141.92,0,0,1,66.11-68.31A141.59,141.59,0,0,1,394,193.45,152.45,152.45,0,0,0,246.59,79.79Z" />
          <path d="M191.59,395.13a140.74,140.74,0,0,1,5,26,152.69,152.69,0,0,0,100,0,140.74,140.74,0,0,1,5-26,152.64,152.64,0,0,0-110,0Z" />
        </g>
        <g className="lekoarts-logo__outer">
          <path d="M106,333.58l-20.7-10.25L91.14,301q1.32-5.05,2.17-10.22A479.12,479.12,0,0,0,31.2,366.71a247.14,247.14,0,0,0,27,39,478.36,478.36,0,0,1,51.53-70.17C108.51,334.88,107.26,334.21,106,333.58Z" />
          <path d="M75.66,209.27l-7.15-10.62,3.25-12.39A180.13,180.13,0,0,1,89.7,142.38,474.44,474.44,0,0,0,9.09,180.07,248.51,248.51,0,0,0,2,278.22a478.68,478.68,0,0,1,78.57-60.91C79.06,214.58,77.42,211.89,75.66,209.27Z" />
          <path d="M94.63,265.91a112.61,112.61,0,0,0-6.52-32.06A478.92,478.92,0,0,0,5.92,300.49,244.74,244.74,0,0,0,21.62,347.7,479.42,479.42,0,0,1,94.63,265.91Z" />
          <path d="M246.59,51.45h0a180.88,180.88,0,0,1,147.93,76.83,473.07,473.07,0,0,1,81.62,28.06,247,247,0,0,0-59.33-88.16A476.6,476.6,0,0,0,277,47.35q-15.34,0-30.46,1-15.1-1-30.45-1A476.6,476.6,0,0,0,76.38,68.17,247,247,0,0,0,17,156.33a473.07,473.07,0,0,1,81.62-28.06A180.88,180.88,0,0,1,246.59,51.45Z" />
          <path d="M246.6,39.87a481.74,481.74,0,0,1,138.07,2.38,245.68,245.68,0,0,0-59.05-29.32,473.36,473.36,0,0,0-79,21.44,473.37,473.37,0,0,0-79-21.44,245.68,245.68,0,0,0-59.05,29.32A481.75,481.75,0,0,1,246.6,39.87Z" />
          <path d="M300,5.82a248.07,248.07,0,0,0-106.85,0A315.87,315.87,0,0,1,246.6,23.68,315.88,315.88,0,0,1,300,5.82Z" />
          <path d="M398.55,265.9a479.42,479.42,0,0,1,73,81.8,244.74,244.74,0,0,0,15.69-47.21,478.92,478.92,0,0,0-82.2-66.65A112.6,112.6,0,0,0,398.55,265.9Z" />
          <path d="M303.94,448.6q-4.33,1.45-8.71,2.66a87.82,87.82,0,0,1-11.53,39.15,245.15,245.15,0,0,0,27.73-5.85q-1.66-18.74-4.72-37.06C305.81,447.9,304.89,448.28,303.94,448.6Z" />
          <path d="M334.32,388.22l-.62,1.36A476.82,476.82,0,0,1,375,457.13a247.64,247.64,0,0,0,45.31-35.57A476.84,476.84,0,0,0,371.55,343,113.58,113.58,0,0,0,334.32,388.22Z" />
          <path d="M324.93,422.52a31,31,0,0,1-6.06,15.27q6.86,19.93,12,40.64a245.18,245.18,0,0,0,28.94-12.69A476.51,476.51,0,0,0,327,410.38,112.75,112.75,0,0,0,324.93,422.52Z" />
          <path d="M484.1,180.07a474.44,474.44,0,0,0-80.62-37.69,180.13,180.13,0,0,1,17.94,43.89l3.25,12.39-7.15,10.62c-1.76,2.62-3.39,5.3-4.92,8a478.67,478.67,0,0,1,78.57,60.91,248.51,248.51,0,0,0-7.08-98.15Z" />
          <path d="M402,301l5.86,22.34-20.7,10.25c-1.27.63-2.53,1.3-3.78,2A478.37,478.37,0,0,1,435,405.72a247.15,247.15,0,0,0,27-39,479.12,479.12,0,0,0-62.11-76Q400.73,295.93,402,301Z" />
          <path d="M189.23,448.59c-.94-.31-1.85-.69-2.75-1.09q-3.07,18.3-4.72,37.06a245.14,245.14,0,0,0,25.4,5.48c-6-11.68-8.75-24.1-9.21-38.78Q193.56,450,189.23,448.59Z" />
          <path d="M166.23,410.39a476.52,476.52,0,0,0-32.81,55.35,245.17,245.17,0,0,0,28.94,12.69q5.11-20.68,12-40.63a31,31,0,0,1-6.07-15.27A112.76,112.76,0,0,0,166.23,410.39Z" />
          <path d="M158.87,388.22A113.59,113.59,0,0,0,121.64,343a476.85,476.85,0,0,0-48.8,78.56,247.64,247.64,0,0,0,45.31,35.57,476.81,476.81,0,0,1,41.33-67.55Z" />
          <path d="M222.15,456.26q.12,18.1,1.58,35.86c7.53.69,15.15,1.06,22.86,1.06s15.34-.37,22.88-1.07q1.44-17.76,1.57-35.86a181.09,181.09,0,0,1-48.9,0Z" />
        </g>
      </g>
    </g>
  </svg>
)

export default Logo

Logo.defaultProps = defaultProps
