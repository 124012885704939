/** @jsx jsx */
import { Container, Header as ThemeHeader, jsx, Flex, Box, useColorMode } from "theme-ui"
import Logo from "../icons/logo"
import ThemeSwitch from "../icons/theme-switch"
import logosrc from "../../static/jetpeaklogo.png"
import whitelogosrc from "../../static/jetpeaklogowhite.png"

const modes = [`light`, `dark`, `strangerThings`]

const Header = () => {
  const [mode, setMode] = useColorMode()

  const cycleMode = (e: any) => {
    const i = (modes.indexOf(mode) + 1) % modes.length
    setMode(modes[i])
  }

  const isLight = mode === `light`

  return (
    <ThemeHeader>
      <Container sx={{ pb: 0 }}>
        <Flex sx={{ alignItems: `center`, justifyContent: `space-between`, flexDirection: [`column`, `row`] }}>
          <Flex>
            <a
              aria-label="Link to the theme creator's website"
              sx={{ color: `text`, "&:hover": { color: `primary` } }}
              href="/"
            >
              <Logo sx={{ width: 12, height: 12 }} />
            </a>
            <Box
              aria-hidden="true"
              focusable="false"
              sx={{ height: 12, width: 1, backgroundColor: `primary`, borderRadius: `full`, mx: 3 }}
            />
            <Flex>
              { isLight ? (
                <img
                  src={logosrc}
                  alt="This is us!"
                  sx={{
                    height: `50px`,
                  }}
                />
              ) :
              (
                <img
                  src={whitelogosrc}
                  alt="This is us!"
                  sx={{
                    height: `48px`,
                  }}
                />
              )}
            </Flex>

          </Flex>
          <Flex sx={{ mt: [4, 0], alignItems: `center` }}>
            <a href="https://app.jetpeak.co/auth/login" sx={{ variant: `buttons.transparent`, mr: 4 }}>
              Log In
            </a>
            <a href="https://app.jetpeak.co/auth/register" sx={{ variant: `buttons.secondary` }}>
              Sign Up
            </a>
            <button
              type="button"
              sx={{ variant: `buttons.transparent`, p: 1, borderRadius: `full`, ml: 4 }}
              title="Change color mode"
              onClick={cycleMode}
            >
              <ThemeSwitch />
            </button>
          </Flex>
        </Flex>
      </Container>
    </ThemeHeader>
  )
}

export default Header
